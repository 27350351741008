<template>
  <div class="about">
    <h1>个人信息</h1>
    <van-form>
      <van-field v-model="username" name="员工姓名" label="员工姓名" placeholder="员工姓名" />
      <van-field v-model="department" name="部门" label="部门" placeholder="部门" />
      <van-field v-model="telephone" name="手机号" label="手机号" placeholder="手机号" />
    </van-form>
  </div>
</template>

<script>
import Vue from 'vue';
import { Form } from 'vant';
import { Field } from 'vant';
import axios from "axios";
import dd from 'dingtalk-jsapi'
Vue.use(Form);
Vue.use(Field);
import { Grid, GridItem, Button, Dialog, Notify } from 'vant';

Vue.use(Grid);
Vue.use(GridItem);
Vue.use(Button);
Vue.use(Dialog);
export default {
  data() {
    return {
      username: '',
      department:'无',
      telephone:'无'
    }
  },
  methods: {
    getDDcode() {
        try{
          dd.getAuthCode({
            corpId: 'dingad716ec89c8623b5f2c783f7214b6d69',
            success: (res) => {
              this.username = res['code']
              this.getUser(res['code'])
            },
            fail: () => {
              this.username = '获取失败'
            },
            complete: () => {
            },
          });
        }catch(error){
          Notify(error)
        }
    },
    getUser(code){
      axios
        .get("http://124.222.100.16:80/python/UserData/", {params: {code: code}})
        .then(
          (response) => {
            this.username = response.data['res']['name']
            this.department = response.data['res']['department']
            this.telephone = response.data['res']['mobile']
            console.log(response.data);
            sessionStorage.setItem('username',this.username)
          },
          (error) => {
            this.username = '获取失败'
            console.log(error.message);
          }
        );
    }
  },
  created() {
    this.getDDcode()
  },
}
</script>

<style lang="scss" scoped></style>
